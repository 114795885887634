<template>
    <div>
        <label for="locale">
            {{ $t('Select your language') }}
        </label>

        <div class="mt-2 relative">
            <button
                id="locale"
                type="button"
                class="w-full border-transparent shadow focus:border-none bg-gray-750 focus:bg-gray-750 outline-none focus:outline-none ring-0 focus:ring-0 rounded transition truncate px-3 py-2"
                @click="toggle"
            >
                <span class="flex items-center text-left">
                    <span class="flex-1 truncate">{{ selected[0] }}</span>
                    <font-awesome-icon icon="fa-solid fa-chevron-down" class="flex-none ml-3 text-gray-400" />
                </span>
            </button>

            <ul
                v-if="isOpen"
                class="absolute w-full bg-gray-750 shadow-modal max-h-56 rounded text-base overflow-auto z-20 top-[37px]"
                tabindex="-1"
            >
                <li
                    v-for="locale in locales"
                    :key="locale[1]"
                    class="cursor-pointer select-none relative py-2 pl-3 pr-9 transition bg-white bg-opacity-0 hover:bg-opacity-10"
                    role="option"
                    @click.prevent="select(locale)"
                >
                    <div class="flex items-center" :class="isSelected(locale) ? 'text-orange-500' : 'text-white'">
                        <span class="flex-1 font-normal ml-3 block truncate">{{ locale[0] }}</span>
                        <font-awesome-icon v-if="isSelected(locale)" icon="fa-solid fa-check" class="flex-none ml-3" />
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { ref } from 'vue';

    import { Locale } from '@/types/locales';

    const { locales, selected } = defineProps<{
        locales: Locale[];
        selected: Locale;
    }>();

    const emits = defineEmits(['changed']);

    const isOpen = ref(false);

    const isSelected = (locale: Locale) => {
        return selected[1] === locale[1];
    };

    const select = (locale: Locale) => {
        emits('changed', locale[1]);
        isOpen.value = false;
    };

    const toggle = () => {
        isOpen.value = !isOpen.value;
    };
</script>
