import { AxiosResponse } from 'axios';
import Form from 'form-backend-validation';

import { api } from '@/services/ApiService';
import { releaseMapper } from '@/services/mappers/release.mapper';

import { ReleaseApiResponse } from '@/types/api/release';
import { ReleaseArtworkWizardTypes, Rightsholder } from '@/types/release';

export default {
    async show({ userUuid, releaseUuid }: { userUuid: string; releaseUuid: string }) {
        const response: AxiosResponse<{ data: ReleaseApiResponse }> = await api.get(
            `/api/users/${userUuid}/releases/${releaseUuid}`,
        );

        return releaseMapper(response.data.data);
    },
    async update({
        userUuid,
        releaseUuid,
        form,
    }: {
        userUuid: string;
        releaseUuid: string;
        form: InstanceType<typeof Form>;
    }) {
        const response: AxiosResponse<ReleaseApiResponse> = await form.put(
            `/api/users/${userUuid}/releases/${releaseUuid}`,
        );

        return releaseMapper(response.data);
    },
    submitRelease({ userUuid, releaseUuid }: { userUuid: string; releaseUuid: string }) {
        return api.post(`/api/users/${userUuid}/releases/${releaseUuid}/submit`);
    },
    getTracks({ userUuid, releaseUuid }: { userUuid: string; releaseUuid: string }) {
        return api.get(`/api/users/${userUuid}/releases/${releaseUuid}/tracks`);
    },
    getTrackUris(releaseUuid: string) {
        return api.get(`/es/s3-presigned-track/${releaseUuid}`);
    },
    getArtworkUris(releaseUuid: string) {
        return api.get(`/es/s3-presigned-artwork/${releaseUuid}`);
    },
    approveArtwork({ userUuid, releaseUuid }: { userUuid: string; releaseUuid: string }) {
        return api.post(`/api/users/${userUuid}/releases/${releaseUuid}/artwork_approve`);
    },
    resetArtwork({ userUuid, releaseUuid }: { userUuid: string; releaseUuid: string }) {
        return api.post(`/api/users/${userUuid}/release/${releaseUuid}/reset-artwork-flow`);
    },
    updateArtworkOptions({
        userUuid,
        releaseUuid,
        form,
    }: {
        userUuid: string;
        releaseUuid: string;
        form: InstanceType<typeof Form>;
    }) {
        return form.post(`/api/users/${userUuid}/releases/${releaseUuid}/artwork_options`);
    },
    attachReleaseGroup({
        userUuid,
        releaseUuid,
        releaseGroupUuid,
    }: {
        userUuid: string;
        releaseUuid: string;
        releaseGroupUuid: string;
    }) {
        return api.put(`/api/users/${userUuid}/releases/${releaseUuid}/release_groups/${releaseGroupUuid}`);
    },
    updateTrack({
        userUuid,
        releaseUuid,
        trackUuid,
        form,
    }: {
        userUuid: string;
        releaseUuid: string;
        trackUuid: string;
        form: InstanceType<typeof Form>;
    }) {
        return form.put(`/api/users/${userUuid}/releases/${releaseUuid}/tracks/${trackUuid}`);
    },
    destroyTrack({ userUuid, releaseUuid, trackUuid }: { userUuid: string; releaseUuid: string; trackUuid: string }) {
        return api.delete(`/api/users/${userUuid}/releases/${releaseUuid}/tracks/${trackUuid}`);
    },
    updateTrackPosition({ userUuid, releaseUuid, tracks }: { userUuid: string; releaseUuid: string; tracks: any[] }) {
        return api.post(`/api/users/${userUuid}/releases/${releaseUuid}/track_positions`, { tracks });
    },
    createTrackUpload({ userUuid, releaseUuid, body }: { userUuid: string; releaseUuid: string; body: any }) {
        return api.post(`/api/users/${userUuid}/releases/${releaseUuid}/track_upload`, body);
    },
    orderProof({ releaseUuid }: { userUuid: string; releaseUuid: string }) {
        return api.post(`/es/cart/proof`, { uuid: releaseUuid });
    },
    cloneRightsHolder({
        userUuid,
        releaseUuid,
        rightsHolder,
    }: {
        userUuid: string;
        releaseUuid: string;
        rightsHolder: Rightsholder;
    }) {
        return api.post(`/api/users/${userUuid}/releases/${releaseUuid}/clone-track-rightholders`, { rightsHolder });
    },
    async fetchArtworkWizardTypes({ userUuid, releaseUuid }: { userUuid: string; releaseUuid: string }) {
        const response: AxiosResponse<{ data: ReleaseArtworkWizardTypes }> = await api.get(
            `/api/users/${userUuid}/release/${releaseUuid}/wizard/types`,
        );

        return response.data.data;
    },
    regenerateArtwork({ userUuid, releaseUuid }: { userUuid: string; releaseUuid: string }) {
        return api.post(`/api/users/${userUuid}/release/${releaseUuid}/wizard/regenerate`);
    },
    failTrackUpload({
        userUuid,
        releaseUuid,
        trackUploadId,
    }: {
        userUuid: string;
        releaseUuid: string;
        trackUploadId: number;
    }) {
        return api.delete(`/api/users/${userUuid}/releases/${releaseUuid}/track_upload/${trackUploadId}`);
    },
};
