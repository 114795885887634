import { AxiosResponse } from 'axios';

import { api } from '@/services/ApiService';

import { Country } from '@/types/locales';

export default {
    async fetchShippingCountries({ force = false }: { force?: boolean }) {
        const response: AxiosResponse<{ data: Country[] }> = await api.get(`/api/shipping_countries`, {
            validateStatus: (status) => {
                return [304, 200].includes(status);
            },
            headers: {
                'Cache-Control': force ? 'no-cache' : 'public',
            },
        });

        return { data: response.data.data, status: response.status };
    },
};
