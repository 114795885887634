import { createStore } from 'vuex';
import VuexPersistence from 'vuex-persist';

import { storeSessionStorageKey } from '@/consts/store';

import account from '@/store/account';
import artist from '@/store/artist';
import cart from '@/store/cart';
import config from '@/store/config';
import genres from '@/store/genres';
import locales from '@/store/locales';
import media from '@/store/media';
import navbar from '@/store/navbar';
import page from '@/store/page';
import pages from '@/store/pages';
import release from '@/store/release';
import releaseGroups from '@/store/release_groups';
import releases from '@/store/releases';
import search from '@/store/search';
import user from '@/store/user';
import websockets from '@/store/websockets';

const store = createStore({
    modules: {
        config,
        account,
        cart,
        release,
        releaseGroups,
        user,
        artist,
        releases,
        page,
        genres,
        media,
        pages,
        navbar,
        websockets,
        locales,
        search,
    },
    plugins: [
        new VuexPersistence({
            storage: window.sessionStorage,
            key: storeSessionStorageKey,
            modules: ['config'],
        }).plugin,
    ],
});

export default store;
