<template>
    <div>
        <label for="country">
            {{ $t('Select your shipping country') }}
        </label>

        <div class="mt-2 relative">
            <button
                id="country"
                type="button"
                class="w-full border-transparent shadow focus:border-none bg-gray-750 focus:bg-gray-750 outline-none focus:outline-none ring-0 focus:ring-0 rounded transition truncate px-3 py-2"
                @click="toggle"
            >
                <span class="flex items-center text-left">
                    <Flag
                        :country="selected?.iso"
                        :rounded="true"
                        class="flex-none m-0"
                        background="/images/vendor/vue-country-flag/dist/flags.png"
                    />
                    <span class="flex-1 ml-3 block truncate">{{ selected?.name }}</span>
                    <font-awesome-icon icon="fa-solid fa-chevron-down" class="flex-none ml-3 text-gray-400" />
                </span>
            </button>

            <ul
                v-if="isOpen"
                class="absolute w-full bg-gray-750 shadow-modal max-h-56 rounded text-base overflow-auto z-20 top-[37px]"
                tabindex="-1"
            >
                <li
                    v-for="country in countries"
                    :key="country.iso"
                    class="cursor-pointer select-none relative py-2 pl-3 pr-9 transition bg-white bg-opacity-0 hover:bg-opacity-10"
                    role="option"
                    @click.prevent="select(country)"
                >
                    <div class="flex items-center" :class="isSelected(country) ? 'text-orange-500' : 'text-white'">
                        <Flag
                            :country="country.iso"
                            :rounded="true"
                            class="flex-none m-0"
                            background="/images/vendor/vue-country-flag/dist/flags.png"
                        />
                        <span class="flex-1 font-normal ml-3 block truncate">{{ country.name }}</span>
                        <font-awesome-icon v-if="isSelected(country)" icon="fa-solid fa-check" class="flex-none ml-3" />
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { ref } from 'vue';
    import Flag from 'vue-country-flag-next';

    import { Country } from '@/types/locales';

    const props = defineProps<{
        countries: Country[];
        selected: Country | null;
    }>();

    const emits = defineEmits(['changed']);

    const isOpen = ref(false);

    const isSelected = (country: Country) => {
        return props.selected?.iso === country.iso;
    };

    const select = (country: Country) => {
        emits('changed', country.iso);
        isOpen.value = false;
    };

    const toggle = () => {
        isOpen.value = !isOpen.value;
    };
</script>
