import GenresService from '@/services/GenresService';

import { Genre } from '@/types/genres';
import { VuexActions } from '@/types/store';
import { GenresActions, GenresGetters, GenresMutations, GenresState } from '@/types/store/genres';

const state: GenresState = {
    items: [],
    current: {},
    releases: [],
    releaseGroups: [],
    mainGenres: [],
    subGenres: [],
};

const getters: GenresGetters = {
    all(state: GenresState) {
        return state.items.sort((a: Genre, b: Genre) => a.name.localeCompare(b.name));
    },
    getMainGenres() {
        return state.mainGenres;
    },
    getSubGenres() {
        return state.subGenres;
    },
};

const actions: GenresActions = {
    async fetch({ commit }: VuexActions) {
        const response = await GenresService.fetch();

        commit('SET_GENRES', response);
    },
    async fetchMainGenres({ commit }: VuexActions) {
        const response = await GenresService.fetchMainGenres();

        commit('SET_MAIN_GENRES', response);
    },
    async fetchSubGenres({ commit }: VuexActions) {
        const response = await GenresService.fetchSubGenres();

        commit('SET_SUB_GENRES', response);
    },
    async fetchReleases({ commit }: VuexActions, genre) {
        const response = await GenresService.fetchReleases(genre);

        commit('SET_RELEASES', response);
    },
    async fetchReleaseGroups({ commit }: VuexActions, genre) {
        const response = await GenresService.fetchReleaseGroups(genre);

        commit('SET_RELEASE_GROUPS', response);
    },
};

const mutations: GenresMutations = {
    SET_GENRES(state: GenresState, genres: Genre[]) {
        state.items = genres;
    },

    SET_MAIN_GENRES(state: GenresState, genres: Genre[]) {
        state.mainGenres = genres;
    },

    SET_SUB_GENRES(state: GenresState, genres: Genre[]) {
        state.subGenres = genres;
    },

    SET_GENRE(state: GenresState, genre: Genre) {
        state.current = genre;
    },

    SET_RELEASES(state: GenresState, releases) {
        state.releases = releases;
    },

    SET_RELEASE_GROUPS(state: GenresState, releaseGroups) {
        state.releaseGroups = releaseGroups;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
