import { UserApiResponse } from '@/types/api/user';
import { User } from '@/types/user';

export const userMapper: (response: UserApiResponse) => User = (response) => {
    return {
        uuid: response.uuid,
        name: response.name,
        first_name: response.name?.split(' ')[0] || '',
        email: response.email,
        is_seller: response.is_seller,
        is_admin: response.is_admin,
        records_order_qty_limit: response.records_order_qty_limit,
        max_releases_reached: response.max_releases_reached,
        releases_limit: response.releases_limit,
        introduction_complete: response.introduction_complete,
        has_2fa: response.has_2fa,
        creator_data: response.creator_data,
        introduction_flags: response.introduction_flags || null,
    };
};
