import { AxiosResponse } from 'axios';

import { api } from '@/services/ApiService';

export default {
    async fetch() {
        const response: AxiosResponse<{ data: any }> = await api.get(`/api/active_genres`);

        return response.data.data;
    },
    async fetchMainGenres() {
        const response: AxiosResponse<{ data: any }> = await api.get(`/api/genres?primary=true`);

        return response.data.data;
    },
    async fetchSubGenres() {
        const response: AxiosResponse<{ data: any }> = await api.get(`/api/genres?secondary=true`);

        return response.data.data;
    },
    async fetchReleases(genre: string) {
        const response: AxiosResponse<{ data: any }> = await api.get(`/api/genres/${genre}/releases`);

        return response.data.data;
    },
    async fetchReleaseGroups(genre: string) {
        const response: AxiosResponse<{ data: any }> = await api.get(`/api/genres/${genre}/release_groups`);

        return response.data.data;
    },
};
