<template>
    <div class="mt-6 mb-24 text-center relative space-y-6 z-10">
        <div class="flex items-center justify-center flex-col sm:flex-row">
            <CountrySelect />

            <div class="flex flex-row items-center mt-4 sm:mt-0 sm:ml-10">
                <a
                    href="https://instagram.com/elasticstage"
                    target="_blank"
                    class="link link-gray text-[25px] leading-[24px] mr-6"
                >
                    <font-awesome-icon icon="fa-brands fa-instagram" />
                </a>

                <a
                    href="https://www.tiktok.com/@elasticstage"
                    target="_blank"
                    class="link link-gray text-[20px] leading-[24px] mr-6"
                >
                    <font-awesome-icon icon="fa-brands fa-tiktok" />
                </a>

                <a
                    href="https://www.youtube.com/channel/UCwCyr7ac9d7xH-7Iu2nacAA"
                    target="_blank"
                    class="link link-gray text-[25px] leading-[24px] mr-6"
                >
                    <font-awesome-icon icon="fa-brands fa-youtube" />
                </a>
            </div>
        </div>
        <div class="flex justify-center flex-col text-gray-200">
            <div class="flex flex-col justify-center sm:flex-row sm:space-x-4">
                <a href="/es/terms" class="link link-gray">{{ $t('Terms & Conditions') }}</a>
                <a href="/es/privacy" class="link link-gray">{{ $t('Privacy Policy') }}</a>
                <a class="cursor-pointer" @click="showSecondLayer">{{ $t('Privacy Settings') }}</a>
            </div>

            <div class="flex flex-col justify-center sm:flex-row sm:space-x-4">
                <a href="https://elasticstage.zendesk.com/hc/en-gb" target="_blank" class="link link-gray">{{
                    $t('FAQs')
                }}</a>
                <a href="/es/contact" class="link link-gray">{{ $t('Contact Us') }}</a>
                <a href="/es/news" class="link link-gray">{{ $t('News') }}</a>
                <a href="https://apply.workable.com/elasticstage/" target="_blank" class="link link-gray">{{
                    $t('Careers')
                }}</a>
            </div>
        </div>
        <div class="text-gray-200 tracking-tight">
            © {{ year }} elasticStage Ltd<br />{{ $t('All rights reserved') }}
        </div>
    </div>
</template>

<script setup lang="ts">
    import CountrySelect from '@/components/global/locales/CountrySelect.vue';

    const showSecondLayer = () => {
        window.UC_UI.showSecondLayer();
    };

    const { year } = defineProps({
        year: {
            type: Number || String,
            required: true,
        },
    });
</script>
