import ConfigService from '@/services/ConfigService';

import { Country } from '@/types/locales';
import { CartActions, ConfigGetters, ConfigState } from '@/types/store/config';

const state: ConfigState = {
    shipping_countries: [],
};

export const getters: ConfigGetters = {
    shippingCountries: (state: ConfigState) => state.shipping_countries,
};

const actions: CartActions = {
    async getShippingCountries({ commit, state }) {
        const { data, status } = await ConfigService.fetchShippingCountries({
            force: state.shipping_countries.length === 0,
        });

        if (status === 200) {
            commit('SET_SHIPPING_COUNTRIES', data);
        }
    },
};

const mutations = {
    SET_SHIPPING_COUNTRIES(state: ConfigState, countries: Country[]) {
        state.shipping_countries = countries;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
};
